import React, { useState, useEffect } from "react";
import axios from "axios";
import "./TableAnalysis.css";
import TableLoader from "./TableLoader";
const QuestionWiseAnalysis = ({ testId }) => {
  const [questionWiseData, setQuestionWiseData] = useState([]);
  const [questionWiseDataLoading, setQuestionWiseDataLoading] = useState(true);
  const [questionWiseDataError, setQuestionWiseDataError] = useState(null);

  useEffect(() => {
    fetchQuestionWiseAnalysis(testId);
    // fetchQuestionWiseAnalysis(testId);
  }, [testId]);
  const fetchQuestionWiseAnalysis = async (testId) => {
    setQuestionWiseDataLoading(true);
    try {
      const response = await axios.post(
        "https://apiv2.questplus.in/api/reports/getQuestionWiseTestAnalysis",
        {
          payload: {
            token: sessionStorage.getItem("token"),
            isJwt: true,
            testId: testId,
          },
          pager: {
            sortField: "TestId",
            sortOrder: "desc",
            rowNumber: 1,
            recordsPerPage: 5,
            filteredRecords: null,
            totalRecords: 0,
          },
        }
      );
      const apiData = response.data.payload;
      console.log("apiData", apiData);
      setQuestionWiseData(apiData);
    } catch (error) {
      setQuestionWiseDataError("Failed to fetch QuestionWise data");
    } finally {
      setQuestionWiseDataLoading(false);
    }
  };

  return (
    <div className="table-container">
      {questionWiseDataLoading ? (
        <TableLoader
          heading={"Question wise Analysis"}
          data={"Loading Question wise Analysis data..."}
          loading={questionWiseDataLoading}
          error={questionWiseDataError}
        />
      ) : questionWiseDataError ? (
        <TableLoader
          heading={"Question wise Analysis"}
          data={"Failed to fetch Question wise Analysis  data"}
          loading={questionWiseDataLoading}
          error={questionWiseDataError}
        />
      ) : (
        <>
          <h2>Question wise Analysis</h2>
          <table>
            <thead>
              <tr>
                <th>Q No.</th>
                <th>Attempt (%)</th>
                <th>Right (%)</th>
                <th>Wrong (%)</th>
                <th>Difficulty Level</th>
                <th>Correct Response</th>
                <th>Your Response</th>
                <th colspan="2">Time Spent (SS)</th>
              </tr>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th>You</th>
                <th>All Student Avg</th>
              </tr>
            </thead>
            <tbody>
              {questionWiseData.map((question, questionIndex) => {
                try {
                  return (
                    <tr key={questionIndex}>
                      <td>{questionIndex + 1}</td>
                      <td>
                        {question?.AttemptPercentage?.toFixed(2) ?? "N/A"}
                      </td>
                      <td>{question?.RightPercentage?.toFixed(2) ?? "N/A"}</td>
                      <td>{question?.WrongPercentage?.toFixed(2) ?? "N/A"}</td>
                      <td>{question?.DifficultyLevel ?? "N/A"}</td>
                      <td>{question?.Correct_Response ?? "N/A"}</td>
                      <td>{question?.Your_Response ?? "N/A"}</td>
                      <td>{question?.Your_Time ?? "N/A"}</td>
                      <td>{question?.All_Student_Average_Time ?? "N/A"}</td>
                    </tr>
                  );
                } catch (error) {
                  console.error(`Error rendering row ${questionIndex}:`, error);
                  return (
                    <tr key={questionIndex}>
                      <td colSpan="9">Error rendering this row</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default QuestionWiseAnalysis;
